* {
  font-family: "Open Sans";
  --ion-default-font:  "Open Sans";
  font-style: normal;
  font-weight: normal;
}

html {
  --ion-font-family: var(--ion-default-font);
}

@media (max-height: 568px) {
  html {
    font-size: 13px;
  }
}

@media (min-height: 568.02px) {
  html {
    font-size: 14px;
  }
}

@media (min-height: 667.02px) {
  html {
    font-size: 15px;
  }
}

@media (min-height: 740.02px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 540px) {
  html {
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  html {
    font-size: 24px;
  }
}

@media (min-width: 1024px) {
  html {
    font-size: 30px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.fnt-600 {
  font-family: 'Open Sans';
  font-weight: 600;
}

.fnt-bold {
  font-family: 'Open Sans';
  font-weight: bold;
}

.fnt-Cft {
  font-family: 'Comfortaa';
}

.fnt-Cft-300 {
  font-family: 'Comfortaa';
  font-weight: 300;
}

.fnt-Cft-600 {
  font-family: 'Comfortaa';
  font-weight: 600;
}

.fnt-Cft-bold {
  font-family: 'Comfortaa';
  font-weight: bold;
}
