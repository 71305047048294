.modal-90-90 {
  .modal-wrapper {
    width: 90%;
    height: 90%;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    background: #FCFCFC;
  }
}

.modal-100-100 {
  .modal-wrapper {
    width: 100%;
    height: 100%;
  }
}
